<template>
  <LoadingWrapper :loading="loading">
    <div v-if="data" class="engagement-wrapper">
      <DlgHeader>
        <IxRes>dlgCert.social.engagement.headers.socialEngagement</IxRes>
      </DlgHeader>
      <div class="grid">
        <div class="cell col-1 empty-div grid-header">
          <!-- empty div in first column -->
        </div>
        <div class="cell col-2 grid-header">
          <DlgInputDescription name="category" />
        </div>
        <div class="cell col-3 grid-header">
          <DlgInputDescription name="involvedEmployee" has-description />
        </div>
        <div class="cell col-4 grid-header">
          <DlgInputDescription name="spentHours" />
        </div>
        <template v-for="(item) in formItems">
          <div :key="`col-1-${ item.name }`" class="cell col-1">
            <IxRes v-if="item.topic">{{ `dlgCert.social.engagement.grid.${ item.topic}` }}</Ixres>
          </div>
          <div :key="`col-2-${ item.name }`" class="cell col-2">
            <IxRes>{{ `dlgCert.social.engagement.grid.${ item.name }` }}</IxRes>
          </div>
          <div :key="`col-3-${ item.name }`" class="cell col-3">
            <IxRes v-if="item.involvedEmployee">{{ `dlgCert.social.engagement.grid.${ item.involvedEmployee}` }}</Ixres>
          </div>
          <div :key="`col-4-${ item.name }`" class="cell col-4">
            <CheckboxLine
              v-if="item.isBoolInput"
              :data="data" :name="item.name"
              @input="update"
            />
            <DlgNumericInput
              v-else
              :value="data[item.name]"
              :addon="item.addon"
              @input="updateValue(item, $event)"
            />
          </div>
        </template>
      </div>
    </div>
  </LoadingWrapper>
</template>

<script>
import ApiMixin from '@dlg-cert/mixins/ApiMixin'

import LoadingWrapper from './shared/LoadingWrapper'

import CheckboxLine from './elements/CheckboxLine'
import DlgInputDescription from './elements/DlgInputDescription'
import DlgNumericInput from './elements/DlgNumericInput'
import DlgHeader from './elements/DlgHeader'

export default {
  components: {
    CheckboxLine,
    DlgHeader,
    DlgInputDescription,
    DlgNumericInput,
    LoadingWrapper
  },
  mixins: [
    ApiMixin
  ],
  data () {
    return {
      formItems: [
        {topic: 'administrationAndPolitics', name: 'municipalArea', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'politicalCommitment', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'justiceDepartement', involvedEmployee: 'manager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'administrationAndPoliticsOther', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        //
        {topic: 'publicInstitutions', name: 'fireProtection', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'emergencyService', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'publicInstitutionsOther', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        //
        {topic: 'otherInstitutions', name: 'environmentalOrganization', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'culturalOrganizations', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'church', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'leisureClubs', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'otherInstitutionsOther', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        //
        {topic: 'socialServices', name: 'employmentOfDisabled', involvedEmployee: '', addon: 'Arbeitszeit in Stunden'},
        {topic: '', name: 'charitySupport', involvedEmployee: 'manager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'socialServicesOther', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        //
        {topic: 'professionalEngagement', name: 'farmersUnionMembership', involvedEmployee: 'employeeAndManager', addon: '€ Jahresbeträge'},
        {topic: '', name: 'otherProfessionalInstitutions', involvedEmployee: 'employeeAndManager', addon: '€ Jahresbeträge'},
        {topic: '', name: 'cooperatives', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'regionalDevelopmentPrograms', involvedEmployee: 'manager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'villageCompetitions', involvedEmployee: 'manager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'voluntaryMunicipalWork', involvedEmployee: 'manager', addon: 'Stunden / Jahr'},
        {topic: '', name: 'scientificInstitutions', involvedEmployee: 'manager', addon: 'Stunden'},
        {topic: '', name: 'professionalEngagementOther', involvedEmployee: 'employeeAndManager', addon: 'Stunden / Jahr'},
        //
        {topic: 'communicationWithPublic', name: 'directMarketing', involvedEmployee: 'entireCompany', addon: 'Stunden / Jahr'},
        {topic: '', name: 'publicEvents', involvedEmployee: 'entireCompany', addon: 'Stunden / Jahr'},
        {topic: '', name: 'broomEconomy', involvedEmployee: 'entireCompany', addon: 'Stunden / Jahr'},
        {topic: '', name: 'agroTourism', involvedEmployee: 'entireCompany', addon: 'Stunden / Jahr'},
        {topic: '', name: 'mediaCoverage', involvedEmployee: 'entireCompany', addon: 'Stunden / Jahr'},
        {topic: '', name: 'communicationDonation', involvedEmployee: 'entireCompany', addon: '€'},
        {topic: '', name: 'compliance', involvedEmployee: 'entireCompany', isBoolInput: true},
        {topic: '', name: 'communicationWithPublicOther', involvedEmployee: 'entireCompany', addon: 'Stunden / Jahr'}
      ]
    }
  },
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/social/${this.$route.params.year}/engagement`
    }
  },
  methods: {
    updateValue (item, value) {
      this.update({name: item.name, value: value})
    }
  }
}
</script>

<style lang="scss" scoped>
.engagement-wrapper {
  overflow: hidden;

  .grid {
    max-height: 70vh;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: 2fr 5fr 1fr 2fr;

    > div {
      padding: 0.25em;
    }

    .grid-header {
      position: sticky;
      top: 0px;
      background-color: white;
      opacity: 1;
      z-index: 30;

      &.col-2, &.col-3, &.col-4 {
        border-bottom: 1px solid black;
      }
    }

    .col-1 {
      margin-left: auto;
      font-weight: bold;
    }

    .col-2, .col-3 {
      border-right: 1px solid black;
    }

    .col-4 {
      display: flex;
    }

    @for $i from 2 through 4 {
      :nth-child(8n + #{$i+8}) {
        background-color: lighten(lightgray, 10%);
      }
    }
  }
}

</style>
